/* @import "~antd/dist/antd.css"; */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.form-fields-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
}

#switch-user {
  align-self: flex-start;
  margin-left: 0;
}

.delete-image {
  translate: 110px -60px;
  position: absolute;
  cursor: pointer;
}

.upload-wrapper {
  margin-bottom: 20px;
}

.icon-button {
  border: 0;
  background: transparent;
  box-shadow: none;
}

.icon-button-restore {
  color: #fff;
  font: rgb(45, 182, 27);
  background: rgb(45, 182, 27);
}

.icon-button:hover {
  background: transparent;
}

.icon-button:focus {
  background: transparent;
}

.actions-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  gap: 4px;
}

.ant-descriptions-item-label {
  font-weight: 700;
  width: 25%;
}

.ticket-draw-container .ant-descriptions-item-label {
  font-weight: 700;
  width: 35%;
}

.delete-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.delete-content p {
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 0;
  padding: 0;
}

.error-message {
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 36px;
}

.ticket-types-actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-types-actions_second_section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.check-in-wrapper {
  padding: 40px;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
}

.current-page {
  margin: 0 4px;
  min-width: 25px;
  max-width: fit-content;
  text-align: center;
  border: 1px solid #3390ff;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.spin-icon {
  font-size: 34px;
}

.enable-switch {
  width: 100%;
}

.statistical-wrapper > .ant-card-body {
  padding: 0;
}

.statistical-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.statistical-grid {
  width: 50%;
}

.statistical-full {
  width: 100%;
}

.statistical-wrapper .statistical-container .ant-card-head {
  text-align: center;
}

.statistical-wrapper .general-container .ant-card-head {
  text-align: center;
}

.statistical-wrapper .statistical-footer {
  padding: 24px 24px 0;
}

.duplicate-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.match-wrapper .form-fields-wrapper {
  margin-bottom: 0;
}

.match-wrapper .switch-button {
  margin-left: 50px;
}

.settings-sync-Odoo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* new CSS */
.country-code-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.width-full {
  width: 100% !important;
}

.create-event-form .ant-space-item {
  width: 100%;
}

.create-event-form .upload-wrapper {
  margin-bottom: 0;
}

.menu-items,
.menu-active {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100% !important;
  padding: 0 10px;
  border-radius: 6px;
}

.menu-items:hover {
  color: #3390ff !important;
  transition: none !important;
  transition-duration: 100ms !important;
}

.menu-active:hover {
  color: white !important;
  transition: none !important;
  transition-duration: 100ms !important;
}

.ant-menu-submenu-title {
  padding: 0 25px !important;
  border-radius: 6px;
}

.ant-menu-submenu-title:hover {
  color: #3390ff !important;
  transition: none !important;
  transition-duration: 100ms !important;
}

.mb-4 {
  margin-bottom: 16px;
}

.bg-gate-manage {
  background-color: #fefbe6 !important;
}

.bg-event-manage {
  background-color: #ddf2db !important;
}

.bg-all-manage {
  background-color: #fff0f0 !important;
}

.event-form .ant-form-item-label > label {
  width: 210px;
}

.event-form .editor-field .ant-form-item-control-input-content {
  height: 203px;
}

.w-full {
  width: 100%;
}

.slider-form .upload-wrapper {
  margin-bottom: 0;
}

.ant-popover-content {
  width: 250px;
}

.ant-popover-content .ant-pro-table-column-setting-list-item-title {
  white-space: nowrap;
}
.ant-popover-content .ant-popover-inner-content {
  width: 100%;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 0;
}

.ant-upload > img {
  height: 100%;
}

.sponsor .upload-wrapper {
  margin: 0;
}

.spin-wrapper {
  width: 100%;
  text-align: center;
  margin: 16px 0;
}

.json-textarea {
  resize: none;
  width: 100%;
  background: #fff;
  padding: 8px 16px;
  line-height: 25px;
}

.wrap-transaction-response {
  padding-top: 20px;
}
